import React from 'react';
import { Helmet } from 'react-helmet';

const BASE_TITLE = 'Codecademy';

export interface PageMetaProps {
  title?: string;
  description?: string;
  noIndex?: boolean;
}

export const PageMeta: React.FC<React.PropsWithChildren<PageMetaProps>> = ({
  title,
  description,
  noIndex,
}) => {
  const docTitle = `${title} | ${BASE_TITLE}`;
  return (
    <Helmet>
      {title && <title>{docTitle}</title>}
      {description && <meta name="description" content={description} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};
